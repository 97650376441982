import React, { Component } from "react";
import MatchDataService from "../services/match.service";
import PhoneInput from 'react-phone-input-2'

export default class AddMatch extends Component {
  constructor(props) {
    super(props);

    this.state = {
        studentfirst:"",
        studentlast:"",
        studentphone:"",
        studentemail:"",
        mentorfirst:"",
        mentorlast:"",
        mentorphone:"",
      mentoremail:"",
      submitted:false,
      published: false
    };

    this.onChangeStudentFirst = this.onChangeStudentFirst.bind(this);
    this.onChangeStudentLast = this.onChangeStudentLast.bind(this);
    this.onChangeStudentPhone = this.onChangeStudentPhone.bind(this);
    this.onChangeStudentEmail = this.onChangeStudentEmail.bind(this);
    this.onChangeMentorFirst = this.onChangeMentorFirst.bind(this);
    this.onChangeMentorLast = this.onChangeMentorLast.bind(this);
    this.onChangeMentorPhone = this.onChangeMentorPhone.bind(this);
    this.onChangeMentorEmail = this.onChangeMentorEmail.bind(this);
    this.saveMatch = this.saveMatch.bind(this);
    this.newMatch = this.newMatch.bind(this);
  }

  onChangeStudentFirst(e) {
    this.setState({
      studentfirst: e.target.value
    });
  }

  onChangeStudentLast(e) {
    this.setState({
      studentlast: e.target.value
    });
  }

  onChangeStudentPhone(number) {
    console.log(number)
    // let num = number.substring(1);
    // console.log(num);
    this.setState({
      studentphone: number
    });
  }

  onChangeStudentEmail(e) {
    this.setState({
      studentemail: e.target.value
    });
  }

  onChangeMentorFirst(e) {
    this.setState({
      mentorfirst: e.target.value
    });
  }

  onChangeMentorLast(e) {
    this.setState({
      mentorlast: e.target.value
    });
  }

  onChangeMentorPhone(number) {
    this.setState({
      mentorphone: number
    });
  }

  onChangeMentorEmail(e) {
    this.setState({
      mentoremail: e.target.value
    });
  }



  saveMatch() {
    let finalStudentNumber = this.state.studentphone.substring(1)
    let finalMentorNumber = this.state.mentorphone.substring(1)
    var data = {
      studentfirst: this.state.studentfirst,
      studentlast: this.state.studentlast,
      studentemail: this.state.studentemail,
      studentphone: finalStudentNumber,
      mentorfirst: this.state.mentorfirst,
      mentorlast: this.state.mentorlast,
      mentorphone: finalMentorNumber,
      mentoremail: this.state.mentoremail
    };

    MatchDataService.create(data)
      .then(response => {
        this.setState({
          id: response.data.id,
          studentfirst: response.data.studentfirst,
          studentlast: response.data.studentlast,
          studentemail: response.data.studentemail,
          studentphone: response.data.studentphone,
          mentorfirst: response.data.mentorfirst,
          mentorlast: response.data.mentorlast,
          mentorphone: response.data.mentorphone,
          mentoremail: response.data.mentoremail,
          submitted: true
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  newMatch() {
    this.setState({
        id: null,
        studentfirst: "",
        studentlast: "",
        studentemail: "",
        studentphone: "",
        mentorfirst: "",
        mentorlast: "",            
        mentorphone: "",
        mentoremail: "",
        published: false,
        submitted: false
    });
  }

  render() {
    return (
      <div className="submit-form">
        {this.state.submitted ? (
          <div>
            <h4>You submitted successfully!</h4>
            <button className="btn btn-success" onClick={this.newMatch}>
              Add
            </button>
          </div>
        ) : (
          <div>
            <div className="form-group">
              <label htmlFor="studentfirst">Student First</label>
              <input
                type="text"
                className="form-control"
                id="studentfirst"
                required
                value={this.state.studentfirst}
                onChange={this.onChangeStudentFirst}
                name="studentfirst"
              />
            </div>

            <div className="form-group">
              <label htmlFor="studentlast">Student Last Name</label>
              <input
                type="text"
                className="form-control"
                id="studentlast"
                required
                value={this.state.studentlast}
                onChange={this.onChangeStudentLast}
                name="studentlast"
              />
            </div>

            <div className="form-group">
              <label htmlFor="studentemail">Student Email</label>
              <input
                type="text"
                className="form-control"
                id="studentemail"
                required
                value={this.state.studentemail}
                onChange={this.onChangeStudentEmail}
                name="studentemail"
              />
            </div>

            <div className="form-group">
              <PhoneInput
                specialLabel="Student Phone"
                placeholder="Enter phone number"
                country={'us'}
                value={this.state.studentphone}
                onChange={this.onChangeStudentPhone}
              />
            </div>



            <div className="form-group">
              <label htmlFor="mentorfirst">Mentor First Name</label>
              <input
                type="text"
                className="form-control"
                id="mentorfirst"
                required
                value={this.state.mentorfirst}
                onChange={this.onChangeMentorFirst}
                name="mentorfirst"
              />
            </div>

            <div className="form-group">
              <label htmlFor="mentorlast">Mentor Last Name</label>
              <input
                type="text"
                className="form-control"
                id="mentorlast"
                required
                value={this.state.mentorlast}
                onChange={this.onChangeMentorLast}
                name="mentorlast"
              />
            </div>

            <div className="form-group">
              <label htmlFor="mentoremail">Mentor Email</label>
              <input
                type="text"
                className="form-control"
                id="mentoremail"
                required
                value={this.state.mentoremail}
                onChange={this.onChangeMentorEmail}
                name="mentoremail"
              />
            </div>


            <div className="form-group">
              <PhoneInput
                placeholder="Enter phone number"
                specialLabel="Mentor Phone"

                country={'us'}
                value={this.state.mentorphone}
                onChange={this.onChangeMentorPhone}
              />
            </div>

            <button onClick={this.saveMatch} className="btn btn-success btn-block">
              Submit
            </button>
          </div>
        )}
      </div>
    );
  }
}