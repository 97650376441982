import React from "react";
import { Route, Switch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { NavBar, Footer, Loading } from "./components";
import { Home, Profile, ExternalApi } from "./views";
import ProtectedRoute from "./auth/protected-route";
import MatchesList from "./components/list-matches.component";
// import Message from "./components/send-msg.component";
import AddMatch from "./components/add-match.component";
import Match from "./components/match.component";
import Message from "./components/send-msg.component";

import "./app.css";

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div id="app" className="d-flex flex-column h-100">
      <NavBar />
      <div className="container flex-grow-1">
        <Switch>
          <Route path="/" exact component={Home} />
          <ProtectedRoute path="/matches" component={MatchesList} />
          <ProtectedRoute path="/add-match" component={AddMatch} />
          <ProtectedRoute path="/match/:id" component={Match} />
          <ProtectedRoute path="/message/:id" component={Message} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

export default App;
