import React from "react";

const logo = "https://cdn.auth0.com/blog/auth0-react-sample/assets/logo.png";

const Hero = () => (
  <div className="text-center hero">
   <div className="mb-3 app-logo logo3"></div>
    <h1 className="mb-4">GoSMS</h1>
    <p className="lead">
      Providing Secure SMS capability for your organization. {" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://gomentor.app"
      >
        GoMentor
      </a>
    </p>
  </div>
);

export default Hero;
