import React, { Component } from "react";
import MatchDataService from "../services/match.service";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

export default class Match extends Component {
  constructor(props) {
    super(props);
    this.getMatch = this.getMatch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
    
    this.state = {
        recipient:null,
        currentMatch: {
            id: null,
            studentfirst: "",
            studentlast: "",
            studentemail: "",
            studentphone: "",
            mentorfirst: "",
            mentorlast: "",
            mentoremail: "",
            mentorphone: "",
            
        },
        message:"",
        sent: false,
    }


    }
    componentDidMount() {
        this.getMatch(this.props.match.params.id);
    }

    getMatch(id) {
        MatchDataService.get(id)
          .then(response => {
            let finalmentorphone = this.formatPhoneNumber(response.data.mentorphone)
            let finalstudentphone = this.formatPhoneNumber(response.data.studentphone)
            response.data.studentphone = finalstudentphone
            response.data.mentorphone = finalmentorphone
            this.setState({
              currentMatch: response.data
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
      }

 
    
    handleSelect=(e)=>{
      let number = e.target.value;
      number = number.replace(/[^\d]/g, ''); 
      console.log(number)
        this.setState({
            recipient:number
        });
    }

    handleMessage=(e)=>{
        this.setState({
            message:e.target.value
        });
    }


    newMatch=(e)=>{
      this.setState({
          message:"",
          recipient:"",
          sent:false
        });

    } 

    formatPhoneNumber(phoneNumberString) {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return null
    }
  

    handleSubmit=(e)=>{
        e.preventDefault();
        let id = this.state.currentMatch._id
        let msg = this.state.message
        let recipient = this.state.recipient
        MatchDataService.createMessage(id, msg,recipient)
          .then(response => {
            this.setState({
              sent: true,
              message:""
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    }

      render() {
        const { currentMatch} = this.state;
        return (
          <div>
            { this.state.sent ? (
          <div>
            <h4>You submitted successfully!</h4>
            <button className="btn btn-success" onClick={this.newMatch}>
              Send Another Message
            </button>

            {/* <button className="btn btn-success" onClick={this.newMatch}>
              Send Another Message
            </button> */}
          </div>
        ) : (
          <div>
              <h1> Send A Message</h1>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Select Receipient</Form.Label>
                    <Form.Control onChange={this.handleSelect} as="select">
                    <option value="null"> Choose A Recipient...</option>
                    {/* <option value={currentMatch.mentorphone}>{ currentMatch.mentorfirst } { currentMatch.mentorlast } - {currentMatch.mentorphone}</option> */}
                      <option value={currentMatch.studentphone}>{ currentMatch.studentfirst } {currentMatch.studentlast} - {currentMatch.studentphone}</option>
                      <option value={currentMatch.mentorphone}>{ currentMatch.mentorfirst } { currentMatch.mentorlast } - {currentMatch.mentorphone}</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Message Content</Form.Label>
                    <Form.Control onChange={this.handleMessage} placeholder="Type your message here...." as="textarea" rows={3} />
                  </Form.Group>
                  <Button className="btn btn-primary btn-large centerButton" type="submit">Send</Button>
                </Form>
                </div>
              )}
        </div>
        )

    }



  
}