import http from "../http-common";

class MatchDataService {
  getAll() {
    return http.get("/matches");
  }

  get(id) {
    return http.get(`/match/${id}`);
  }

  createMessage(id, message, recipient) {
      let obj = {
        "message" : message,
        "recipient" : recipient
      }
    return http.post(`/match/send-message/${id}`, obj);
  }

  create(data) {
    return http.post("/add-match", data);
  }

  update(id, data) {
    return http.post(`/match/edit/${id}`, data);
  }

  delete(id) {
    return http.post(`/match/delete/${id}`);
  }

  deleteAll() {
    return http.delete(`/matches`);
  }

  findByName(name) {
    return http.get(`/matches?name=${name}`);
  }
}

export default new MatchDataService();