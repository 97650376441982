import React, { Component } from "react";
import MatchDataService from "../services/match.service";
import PhoneInput from 'react-phone-input-2'

export default class Match extends Component {
  constructor(props) {
    super(props);
    this.onChangeStudentFirst = this.onChangeStudentFirst.bind(this);
    this.onChangeStudentLast = this.onChangeStudentLast.bind(this);
    this.onChangeStudentEmail = this.onChangeStudentEmail.bind(this);
    this.onChangeStudentPhone = this.onChangeStudentPhone.bind(this);
    this.onChangeMentorFirst = this.onChangeMentorFirst.bind(this);
    this.onChangeMentorLast = this.onChangeMentorLast.bind(this);
    this.onChangeMentorEmail = this.onChangeMentorEmail.bind(this);
    this.onChangeMentorPhone = this.onChangeMentorPhone.bind(this);
    this.getMatch = this.getMatch.bind(this);
    this.updatePublished = this.updatePublished.bind(this);
    this.updateMatch = this.updateMatch.bind(this);
    this.deleteMatch = this.deleteMatch.bind(this);
    console.log(props)
    this.state = {
      currentMatch: {
        id: null,
        studentfirst: "",
        studentlast: "",
        studentemail: "",
        studentphone: "",
        mentorfirst: "",
        mentorlast: "",
        mentoremail: "",
        mentorphone: "",
        published: false
      },
      message: ""
    };
  }

  componentDidMount() {
    this.getMatch(this.props.match.params.id);
  }

  onChangeStudentFirst(e) {
    const studentfirst = e.target.value;

    this.setState(function(prevState) {
      return {
        currentMatch: {
          ...prevState.currentMatch,
          studentfirst: studentfirst
        }
      };
    });
  }
  onChangeStudentLast(e) {
    const studentlast = e.target.value;

    this.setState(function(prevState) {
      return {
        currentMatch: {
          ...prevState.currentMatch,
          studentlast: studentlast
        }
      };
    });
  }

  onChangeStudentPhone(number) {


    this.setState(function(prevState) {
      return {
        currentMatch: {
          ...prevState.currentMatch,
          studentphone: number
        }
      };
    });
  }
  onChangeStudentEmail(e) {
    const studentemail= e.target.value;

    this.setState(function(prevState) {
      return {
        currentMatch: {
          ...prevState.currentMatch,
          studentemail: studentemail
        }
      };
    });
  }

  onChangeMentorFirst(e) {
    const mentorfirst = e.target.value;

    this.setState(function(prevState) {
      return {
        currentMatch: {
          ...prevState.currentMatch,
          mentorfirst: mentorfirst
        }
      };
    });
  }
  onChangeMentorLast(e) {
    const mentorlast = e.target.value;

    this.setState(function(prevState) {
      return {
        currentMatch: {
          ...prevState.currentMatch,
          mentorlast: mentorlast
        }
      };
    });
  }
  onChangeMentorPhone(number) {


    this.setState(function(prevState) {
      return {
        currentMatch: {
          ...prevState.currentMatch,
          mentorphone: number
        }
      };
    });
  }
  onChangeMentorEmail(e) {
    const mentoremail= e.target.value;

    this.setState(function(prevState) {
      return {
        currentMatch: {
          ...prevState.currentMatch,
          mentoremail: mentoremail
        }
      };
    });
  }



  getMatch(id) {
    MatchDataService.get(id)
      .then(response => {
        let finalMentorNumber = `${1 + response.data.mentorphone}`
        let finalStudentNumber = `${1 + response.data.studentphone}`
        response.data.mentorphone = finalMentorNumber
        response.data.studentphone = finalStudentNumber
        
        this.setState({
          currentMatch: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  updatePublished(status) {
    var data = {
      id: this.state.currentMatch.id,
      title: this.state.currentMatch.title,
      description: this.state.currentMatch.description,
      published: status
    };

    MatchDataService.update(this.state.currentMatch.id, data)
      .then(response => {
        this.setState(prevState => ({
          currentMatch: {
            ...prevState.currentMatch,
            published: status
          }
        }));
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  

  updateMatch() {

    let studentphone = this.state.currentMatch.studentphone.substring(1)
    let mentorphone =  this.state.currentMatch.mentorphone.substring(1)
    console.log(studentphone, mentorphone)
    this.state.currentMatch.mentorphone = mentorphone 
    this.state.currentMatch.studentphone = studentphone 

    MatchDataService.update(
      this.state.currentMatch._id,
      this.state.currentMatch
    )
      .then(response => {
        console.log(response.data);
        this.setState({
          message: "The Match was updated successfully!"
        });
        this.props.history.push('/matches/');
      })
      .catch(e => {
        console.log(e);
      });
  }

  deleteMatch() {    
    MatchDataService.delete(this.state.currentMatch._id)
      .then(response => {
        console.log(response.data);
        this.props.history.push('/matches')
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    const { currentMatch } = this.state;

    return (
      <div>
        {currentMatch ? (
          <div className="edit-form">
            <h4>Match</h4>
            <form>
              <div className="form-group">
                <label htmlFor="studentfirst">Student First</label>
                <input
                  type="text"
                  className="form-control"
                  id="studentfirst"
                  value={currentMatch.studentfirst}
                  onChange={this.onChangeStudentFirst}
                />
              </div>
              <div className="form-group">
                <label htmlFor="studentlast">Student Last</label>
                <input
                  type="text"
                  className="form-control"
                  id="studentlast"
                  value={currentMatch.studentlast}
                  onChange={this.onChangeStudentLast}
                />
              </div>

              <div className="form-group">
                <label htmlFor="studentemail">Student Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="studentemail"
                  value={currentMatch.studentemail}
                  onChange={this.onChangeStudentEmail}
                />
              </div>

    

            <div className="form-group">
              <PhoneInput
                specialLabel="Student Phone"
                placeholder="Enter phone number"
                country={'us'}
                value={currentMatch.studentphone}
                onChange={this.onChangeStudentPhone}
              />
            </div>

              {/* <div className="form-group">
              <label htmlFor="studentphone">Student Phone</label>
              <PhoneInput
                placeholder="Enter phone number"
                country={'us'}
                value={currentMatch.studentphone}
                onChange={this.onChangeStudentPhone}
              />
            </div> */}
              <div className="form-group">
                <label htmlFor="mentorfirst">Mentor First</label>
                <input
                  type="text"
                  className="form-control"
                  id="mentorfirst"
                  value={currentMatch.mentorfirst}
                  onChange={this.onChangeMentorFirst}
                />
              </div>
              <div className="form-group">
                <label htmlFor="mentorlast">Mentor Last</label>
                <input
                  type="text"
                  className="form-control"
                  id="mentorlast"
                  value={currentMatch.mentorlast}
                  onChange={this.onChangeMentorLast}
                />
              </div>
              <div className="form-group">
                <label htmlFor="mentoremail">Mentor Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="mentoremail"
                  value={currentMatch.mentoremail}
                  onChange={this.onChangeMentorEmail}
                />
              </div>

            <div className="form-group">
              <PhoneInput
                specialLabel="Mentor Phone"
                placeholder="Enter phone number"
                country={'us'}
                value={currentMatch.mentorphone}
                onChange={this.onChangeMentorPhone}
              />
            </div>

              
            </form>

            <button
              className="btn btn-danger btn-block"
              onClick={this.deleteMatch}
            >
              Delete
            </button>
 {/* <p style="margin:0 auto;" >OR</p> */}
            <button
              type="submit"
              className="btn btn-success btn-block"
              onClick={this.updateMatch}
            >
              Update
            </button>
            <p>{this.state.message}</p>
          </div>
        ) : (
          <div>
            <br />
            <p>Please click on a Match...</p>
          </div>
        )}
      </div>
    );
  }



}