import React, { Component } from "react";
import MatchDataService from "../services/match.service";
import { Link } from "react-router-dom";

export default class MatchesList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.retrieveMatches = this.retrieveMatches.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveMatch = this.setActiveMatch.bind(this);
    this.removeAllMatch = this.removeAllMatches.bind(this);
    this.searchTitle = this.searchTitle.bind(this);
    this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
    this.state = {
      matches: [],
      currentMatch: null,
      currentIndex: -1,
      searchTitle: ""
    };
  }

  componentDidMount() {
    this.retrieveMatches();
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle
    });
  }

  retrieveMatches() {
    MatchDataService.getAll()
      .then(response => {
        this.setState({
          matches: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveMatches();
    this.setState({
      currentMatch: null,
      currentIndex: -1
    });
  }

  setActiveMatch(match, index) {
    let finalmentorphone = this.formatPhoneNumber(match.mentorphone)
    let finalstudentphone = this.formatPhoneNumber(match.studentphone)
    match.studentphone = finalstudentphone
    match.mentorphone = finalmentorphone
    this.setState({
      currentMatch: match,
      currentIndex: index
    });
  }

  removeAllMatches() {
    MatchDataService.deleteAll()
      .then(response => {
        console.log(response.data);
        this.refreshList();
      })
      .catch(e => {
        console.log(e);
      });
  }

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  searchTitle() {
    // let searchValue = this.state.searchTitle;
    // this.state.matches
    //   .filter

    // this.state.matches.filter(function(person) {
    //   console.log(person)
    //   person.filter(function(value) {
    //     console.log(value)

    //     // return fieldToFind.Name === field.DependencyField;
    //   });
    //   // return Object.values(person).forEach((val) => {
    //   //   if(String(val) === value) {
    //   //     console.log('HORRAY', value, val)
    //   //     destArr.push(person)
    //   //     return destArr;
    //   //   }    
    //   // });
    // });



    // MatchDataService.findByTitle(this.state.searchTitle)
    //   .then(response => {
    //     this.setState({
    //       matches: response.data
    //     });
    //     console.log(response.data);
    //   })
    //   .catch(e => {
    //     console.log(e);
    //   });
  }

  render() {
    const { searchTitle, matches, currentMatch, currentIndex } = this.state;

    return (
      <div className="list row">
        {/* <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Name"
              value={searchTitle}
              onChange={this.onChangeSearchTitle}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={this.searchTitle}
              >
                Search
              </button>
            </div>
          </div>
        </div> */}
        <div className="col-md-6">
          <h4>Matches List</h4>

          <ul className="list-group">
            {matches &&
              matches.map((match, index) => (
                
                <li 
                  className={
                    "list-group-item " +
                    (index === currentIndex ? "active" : "")
                  }
                  onClick={() => this.setActiveMatch(match, index)}
                  key={index}
                >
                  {match.studentfirst} {match.studentlast} - {match.mentorfirst} {match.mentorlast}
                </li>
              ))}
          </ul>

          {/* <button
            className="m-3 btn btn-sm btn-danger"
            onClick={this.removeAllMatches}
          >
            Remove All
          </button> */}
        </div>
        <div className="col-md-6">
        
          {currentMatch ? (
            <div>
              <h4>Match Details</h4>
              <div className="row action-row">
                  <div className="col-md-4">
                    <Link
                        to={"/match/" + currentMatch._id}
                        className="btn btn-warning"
                    >
                        Edit Match
                    </Link>
                  </div>
                  {/* <div className="col-md-4">
                    <Link
                        to={`/message/${currentMatch._id}`}
                        className="btn btn-info"
                    >
                        View Messages
                    </Link>
                  </div> */}
                  <div className="col-md-4">
                    <Link
                    type="button"
                        to={`/message/${currentMatch._id}`}
                        className="btn btn-info"
                    >
                        Send Message
                    </Link>
                    {/* <button type="button" class="btn btn-primary">Primary</button> */}

                  </div>
                  <div className="row">
                      <div className="col-md-12">
           
                      </div>
                  </div>
              </div>

              <div className="card" >
  {/* <img className="card-img-top" alt="Card image cap"/> */}
  <div className="card-body">
    <h5 className="card-title">Information</h5>
    <div className="row">
              <div className="col-md-6">
            

              <div>
                <label>
                  <strong>Student First Name:</strong>
                </label>{" "}
                <p>{currentMatch.studentfirst}</p>
              </div>
              <div>
                <label>
                  <strong>Student Last Name:</strong>
                </label>{" "}
                <p>{currentMatch.studentlast}</p>
              </div>
              <div>
                <label>
                  <strong>Student email:</strong>
                </label>{" "}
                <p>{currentMatch.studentemail}</p>
              </div>
              <div>
                <label>
                  <strong>Student phone:</strong>
                </label>{" "}
                <p>{currentMatch.studentphone}</p>
              </div>
              <div>
                <label>
                  <strong>Messages:</strong>
                </label>{" "}
                <p>{currentMatch.messages.length}</p>
              </div>
              </div>
              <div className="col-md-6">
              <div>
                <label>
                  <strong>Mentor First Name:</strong>
                </label>{" "}
                <p>{currentMatch.mentorfirst}</p>
              </div>
              <div>
                <label>
                  <strong>Mentor Last Name:</strong>
                </label>{" "}
                <p>{currentMatch.mentorlast}</p>
              </div>
              <div>
                <label>
                  <strong>Mentor email:</strong>
                </label>{" "}
                <p>{currentMatch.mentoremail}</p>
              </div>
              <div>
                <label>
                  <strong>Mentor phone:</strong>
                </label>{" "}
                <p>{currentMatch.mentorphone}</p>
              </div>

              </div>
              </div>
  </div>
</div>
             

              <div className="row action-row">
              
                  <div className="row">
                      <div className="col-md-12">
            <ul className="list-group-two">
            {
              currentMatch.messages.map((msg, index) => (
                // <li
                //   className={
                //     "list-group-item " +
                //     (index === currentIndex ? "active" : "")
                //   }
                // //   onClick={() => this.setActiveMatch(msg, index)}
                //   key={index}
                // >
                <li className="list-group-item ">
                  {msg.message} {msg.sender} - {msg.recipient} 
                </li>
              ))}
          </ul>
                      </div>
                  </div>
              </div>

              
            </div>
          ) : (
            <div>
              <br />
              <p>Please click on a Match...</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}