import React from "react";

import MainNav from "./main-nav";
import AuthNav from "./auth-nav";
import Navbar from 'react-bootstrap/Navbar'
const NavBar = () => {
  return (
    <div className="nav-container mb-3">
      <nav className="navbar navbar-expand-md navbar-light bg-light">
        <div className="container">
          <div className="navbar-brand logo2" />
          <MainNav />
          <AuthNav />
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
