import React from "react";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <div className = "logo4"></div>
    <p>
      GoSMS provided by {" "}
      <a target="_blank" rel="noopener noreferrer" href="https://gomentor.app">
        GoMentor
      </a>
    </p>
  </footer>
);

export default Footer;
